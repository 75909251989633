<template>
    <HomeCard
        :style="meshStyles"
        class="!max-w-full w-full"
        :class="{ '!border-none': useMeshBackground }"
        :show-arrow="showArrow"
        :arrow-background-color="arrowBackgroundColor"
        @click="handleClick">
        <div class="flex flex-col justify-between gap-4" :class="[useMeshBackground ? 'text-white' : 'text-[#8C8C8C]', { grow: !content }]">
            <slot name="header">
                <div class="flex items-center gap-2">
                    <i class="bi bi-lightning-charge-fill text-2xl"></i>
                    <p v-if="headerTitle" class="font-semibold tracking-[-0.64px]">{{ headerTitle }}</p>
                </div>
            </slot>
            <h3 class="md:text-2xl font-semibold tracking-tighter">{{ insightTitle }}</h3>
            <slot name="content">
                <BaseCollapsible v-model:open="isOpen" class="flex flex-col-reverse items-start">
                    <template #trigger>
                        <div class="font-semibold tracking-[-0.64px] p-3 -ml-3">{{ isOpen ? "See Less" : "See more" }}</div>
                    </template>
                    <template #content>
                        <span v-if="!!content" class="font-medium tracking-[-0.64px]">{{ content }}</span>
                    </template>
                </BaseCollapsible>
            </slot>
        </div>
    </HomeCard>
</template>

<script setup>
import { ACTION } from "~vue/chatActions";
import BaseCollapsible from "~vue/components/BaseCollapsible.vue";
import { useMeshGradient } from "~vue/composables/useMeshGradient";
import { openUrl } from "~vue/utils";
import { CHATSOURCE } from "~vue/utils/constants.js";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, ref } from "vue";

import HomeCard from "./HomeCard.vue";

const { title, url, content, useMeshBackground, type } = defineProps({
    title: { type: String, default: "" },
    url: { type: String, default: "" },
    content: { type: String, default: "" },
    showArrow: { type: Boolean, default: true },
    type: { type: String, default: "" },
    useMeshBackground: { type: Boolean, default: false },
});

const isOpen = ref(false);

const headerTitle = computed(() => {
    if (type === ACTION.VALUES_INSIGHT) {
        return "Your hidden advantage";
    } else if (type === ACTION.INTERNAL_THINKING) {
        return "A potential blindspot";
    }
    return "";
});

const insightTitle = computed(() => {
    const firstSentence = content.split(". ")[0] + ".";
    return title ?? firstSentence;
});

const handleClick = () => {
    logUserInteraction("saved_insights_clicked", { title: title });
    if (url) {
        openUrl(url, { queryParams: { chat_source: CHATSOURCE.HOME_INSIGHTS } });
    }
};

const { styles } = useMeshGradient({
    backgroundColor: "#487a92",
    meshColors: ["#aa8a72", "#a9aaa0", "#766d7f", "#426aa9"],
});

const meshStyles = computed(() => (useMeshBackground ? styles : undefined));
const arrowBackgroundColor = computed(() => (useMeshBackground ? "#FFFFFF" : undefined));
</script>
