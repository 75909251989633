import { getRandomInt } from "~vue/utils/random";

const randomPosition = () => {
    const x = getRandomInt(0, 100);
    const y = getRandomInt(0, 100);
    return `${x}% ${y}%`;
};

const radialGradient = (color, size) => {
    const circleSize = size + getRandomInt(0, 200);
    return `radial-gradient(circle ${circleSize}px at ${randomPosition()}, ${color} 0px, transparent 50%)`;
};

/**
 * given a set of colors (background + mesh colors) generates styles to create a mesh gradient
 */
export const useMeshGradient = ({ backgroundColor, meshColors, size = 500 }) => {
    return {
        styles: {
            backgroundColor: backgroundColor,
            backgroundImage: meshColors.map((color) => radialGradient(color, size)).join(", "),
        },
    };
};
