<template>
    <HomeSectionCardsContainer title="What I've noticed about you" subtitle="Insights" :card-props="insights" class="section-container" :class="{ empty: insightCount === 0 }">
        <template v-if="insightCount > 0" #cards="{ cardProps }">
            <HomeInsightCard :title="cardProps.title" :content="cardProps.text" :url="cardProps.chat_url" :type="cardProps.type" use-mesh-background />
        </template>
        <template v-else #cards="{ cardProps, index }">
            <HomeInsightCard v-if="index === 0" :title="cardProps.title" :url="defaultChatUrl" :show-arrow="false">
                <template #header>
                    <CoachingModeMarble class="w-8 h-8" />
                </template>
                <template #content>
                    <!-- top-2 aligns with the arrow from side by side cards -->
                    <BaseButton theme="startChat" class="w-fit flex items-center top-2">
                        Start Chat
                        <span class="rounded-full flex items-center justify-center p-4 w-5 h-5 bg-slate-300 self-end">
                            <i class="bi bi-arrow-right text-lg text-[#555BA2] icon-bold"></i> </span
                    ></BaseButton>
                </template>
            </HomeInsightCard>
            <HomeInsightCard v-else :title="cardProps.title" :url="defaultChatUrl">
                <template #header>
                    <i class="bi bi-lock text-2xl"></i>
                </template>
                <template #content>
                    <div class="flex flex-col gap-1">
                        <SkeletonLoader v-for="(_, i) in Array(3)" :key="i" class="h-2 w-full rounded-full bg-[#E8E8E8]" />
                        <SkeletonLoader class="h-2 w-3/5 rounded-full bg-[#E8E8E8]" />
                    </div>
                </template>
            </HomeInsightCard>
        </template>
    </HomeSectionCardsContainer>
</template>

<script setup>
import BaseButton from "~vue/components/BaseButton.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import SkeletonLoader from "~vue/components/SkeletonLoader.vue";
import { computed } from "vue";

import HomeInsightCard from "./HomeInsightCard.vue";
import HomeSectionCardsContainer from "./HomeSectionCardsContainer.vue";

const { userInsights, defaultChatUrl } = defineProps({
    userInsights: {
        type: Array,
        required: true,
    },
    defaultChatUrl: {
        type: String,
        required: true,
    },
});

// used for showing empty state
const emptyStateFiller = [{ title: "Unlock your insights" }, { title: "A potential blindspot" }, { title: "Tactical follow-up" }];
const insights = computed(() => (userInsights.length > 0 ? userInsights : emptyStateFiller));
const insightCount = computed(() => userInsights.length);
</script>

<style scoped>
.section-container :deep(.card-container) {
    min-width: 100%;
}

@screen sm {
    /* overrides card container within HomeSectionCardsContainer to occupy 50% - gap px */
    .section-container :deep(.card-container) {
        min-width: calc(50% - 12px);
    }
}

/* the empty state insights occupy space different, the last 2 cards stretch the rest of the space */
.empty {
    width: 100%;

    & :deep(.card-container) {
        min-width: auto;

        &:not(:first-child) {
            flex-grow: 1;
        }
    }
}
</style>
