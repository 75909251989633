<template>
    <HomeSectionContainer>
        <template #title>
            <div class="flex justify-between items-center gap-2">
                <div>
                    <h3 v-if="subtitle" class="text-base font-semibold tracking-tight text-[#8C8C8C]">{{ subtitle }}</h3>
                    <h3 class="text-2xl font-semibold tracking-tight">{{ title }}</h3>
                </div>
                <div v-if="showControls && !isNotScrollable" class="flex gap-3">
                    <button
                        :disabled="arrivedState.left"
                        type="button"
                        class="group transition-colors font-semibold rounded-full flex items-center justify-center p-4 w-5 h-5 bg-[#555BA2]/25 disabled:bg-[#E8E8E8] self-end"
                        @click="x -= cardWidth">
                        <i class="bi bi-arrow-left text-lg text-[#555BA2] group-disabled:text-[#8C8C8C] icon-bold"></i>
                    </button>

                    <button
                        :disabled="arrivedState.right"
                        type="button"
                        class="group transition-colors font-semibold rounded-full flex items-center justify-center p-4 w-5 h-5 bg-[#555BA2]/25 disabled:bg-[#E8E8E8] self-end"
                        @click="x += cardWidth">
                        <i class="bi bi-arrow-right text-lg text-[#555BA2] group-disabled:text-[#8C8C8C] icon-bold"></i>
                    </button>
                </div>
            </div>
        </template>
        <div ref="cardContainer" class="flex gap-4 overflow-x-auto snap-x h-full">
            <div v-for="(props, index) in cardProps" ref="cardInstance" :key="index" class="snap-start card-container">
                <slot name="cards" :card-props="props" :index="index"></slot>
            </div>
            <slot name="additional-content"></slot>
        </div>
        <slot></slot>
    </HomeSectionContainer>
</template>

<script setup>
// this composes HomeSectionContainer, with support for scrolling and snapping for card elements displayed
import { useScroll } from "@vueuse/core";
import { computed, useTemplateRef } from "vue";

import HomeSectionContainer from "./HomeSectionContainer.vue";

defineProps({
    title: { type: String, required: true },
    subtitle: { type: String, default: "" },
    cardProps: { type: Array, required: true },
    showControls: { type: Boolean, default: true },
});

const cardContainer = useTemplateRef("cardContainer");
const { x, arrivedState } = useScroll(cardContainer, { behavior: "smooth" });
const isNotScrollable = computed(() => arrivedState.left && arrivedState.right);

const cardInstance = useTemplateRef("cardInstance");
const cardWidth = computed(() => {
    if (cardInstance.value.length > 0) {
        const card = cardInstance.value[0];
        return parseInt(window.getComputedStyle(card).width);
    }
    return 0;
});
</script>
